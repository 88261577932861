@keyframes placeHolderShimmer{
    0%{
        background-position: -468px 0
    }
    100%{
        background-position: 468px 0
    }
}
.linear-background {
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: placeHolderShimmer;
    animation-timing-function: linear;
    background: #f6f7f8;
    background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    background-size: 1000px 104px;
    height: 187px;
    position: relative;
    overflow: hidden;
}
.owl-carousel .owl-nav button.owl-prev, .owl-carousel .owl-nav button.owl-next {
    font-size: 2rem !important;
    outline: none;
}
.dummy {
    animation-name: placeHolderShimmer;
    background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
}
.strip figure img {
    top: 46%;
}
.list_home ul li a figure img {
    height: 135px !important;
}
.in-stock {
    color: rgb(0, 184, 83);
    width: 70px;
}
.owl-theme.categories_carousel_m .item a i {
    font-size: 70px;
    font-size: 4.375rem;
    margin: 0;
    padding: 0;
    display: block;
}
.owl-theme.categories_carousel_m .item a:hover {
    -webkit-box-shadow: 0 20px 20px -20px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0 20px 20px -20px rgba(0, 0, 0, 0.25);
    box-shadow: 0 20px 20px -20px rgba(0, 0, 0, 0.25);
}
.owl-theme.categories_carousel_m .item a, .item_version_2 a {
    display: block;
}
.owl-theme.categories_carousel_m .item a h3 {
    font-size: 18px;
    font-size: 1.125rem;
    margin: 0;
}
.owl-theme.categories_carousel_m .item a {
    background-color: #fff;
    padding: 30px 15px;
    text-align: center;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
    position: relative;
    margin-bottom: 25px;
}
.badge {
    color: #83c55e;
    display: block;
    padding: 2px;
    text-align: center;
    font-weight: bold;
    position: absolute;
    top: -1px;
    left: 10px;
    font-size: 16px;
    text-shadow: 1px 1px 1px #2b2828,0px 1px 1px #0f0f0f;
    border-radius: 25px;
    width: auto;
}
table {
    border-collapse: collapse;
}
.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
}
.cart-table-content table {
    border: 1px solid #ebebeb;
}
.cart-table-content table thead > tr th {
    padding: 21px 35px 22px;
}
.cart-table-content table thead > tr th {
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    vertical-align: middle;
    white-space: nowrap;
    text-transform: uppercase;
    color: #333;
    border-top: none;
}
.cart-table-content table tbody > tr {
    border-bottom: 1px solid #ebebeb;
}
.cart-table-content table tbody > tr td.product-thumbnail {
    width: 150px;
}
.cart-table-content table tbody > tr td {
    font-size: 15px;
    padding: 0;
    text-align: center;
    color: #333;
}
.cart-table-content table thead > tr {
    border: 1px solid #ebebeb;
    background-color: #f9f9f9;
}
.cart-table-content table tbody > tr td.product-quantity .cart-plus-minus input.cart-plus-minus-box {
    font-size: 14px;
    float: left;
    width: 47px;
    height: 40px;
    margin: 0;
    padding: 0;
    text-align: center;
    color: #333;
    border: 1px solid #e1e1e1;
    background: transparent none repeat scroll 0 0;
    text-align: center;
}
.qtybutton {
    float: left;
    border: 1px solid #e5e5e5;
    height: 40px;
}

.checkout {
    background-color: rgb(255, 255, 255);
    padding: 30px 30px 20px;
    position: relative;
    margin-bottom: 20px;
    box-shadow: rgba(0, 0, 0, 0.08) 0px 2px 16px 0px;
}

.checkout .head {
    font-family: Lato, sans-serif;
    font-size: 21px;
    font-weight: 400;
    color: rgb(13, 17, 54);
    line-height: 1.2;
    margin-bottom: 35px;
    display: flex;
    -moz-box-align: center;
    align-items: center;
}

.checkout .head a {
    width: auto;
    height: auto;
    display: flex;
    -moz-box-align: center;
    align-items: center;
    background-color: transparent;
    border: 0px none;
    outline: currentcolor none 0px;
    border-radius: 0px;
    padding: 0px;
    font-family: Lato, sans-serif;
    font-size: 13px;
    font-weight: 500;
    color: #589442;
    position: absolute;
    top: 40px;
    right: 30px;
}
.checkout .card {
    padding: 10px;
    border-radius: 4px;
    border-radius: 6px;
    background-color: rgb(247, 247, 247);
    border: 1px solid rgb(247, 247, 247);
    font-weight: normal !important;
}
.checkout .card .address-type {
    font-family: Lato, sans-serif;
    font-size: 13px;
    font-weight: 700;
    color: rgb(13, 17, 54);
    line-height: 1.2;
    margin-bottom: 5px;
    text-transform: capitalize;
}
.checkout .card.active {
    border: 1px solid rgb(0, 158, 127);
    background-color: rgb(255, 255, 255);
}
.glassmorphisam {
    background: rgba( 255, 255, 255, 0.25 );
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    -webkit-backdrop-filter: blur( 4px );
    backdrop-filter: blur(4px);
    border-radius: 4px;
    border: 1px solid rgba( 255, 255, 255, 0.18 );
    padding: 10px;
}

.cardModel {
    position: relative;
    width: 400px;
    margin: 10px;
    box-shadow: 2px 0px 50px rgb(0 0 0 / 50%);
    border-radius: 4px;
    background: rgb(255, 255, 255, 1);
    overflow: hidden;
    display: flex;
    border-top: 1px solid rgba( 255, 255, 255, 0.5);
    border-left: 1px solid rgba( 255, 255, 255, 0.5);
    backdrop-filter: blur(5px);
    padding: 10px;
}
.cardModel .content {
    width: 100%;
}


.loading {
    background-repeat: no-repeat;
    /*background-image:linear-gradient(#ecebeb 100%, transparent 0);*/
    /*background: linear-gradient(to right, #8b0606 8%, #dddddd 18%, #eeeeee 33%);*/
}
.loading::after {
    animation-duration: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    animation-name: loading;
    animation-timing-function: linear;
    background: #f6f7f8;
    background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
    position: relative;
    overflow: hidden;
    animation: placeHolderShimmer 1s infinite;
    content: "";
    display: block;
    width: 100%;
    height: 100%;
}

@keyframes loading {
    to {
        background-position:
            0 0,
            100% 0, /* move highlight to right */
            120px 0,
            120px 40px,
            120px 80px,
            120px 120px;
    }
}

.skeleton-loader:empty {
    width: 100%;
    height: 15px;
    display: block;
    background: linear-gradient( to right, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.5) 100%, rgba(255, 255, 255, 0) 80% ), #f9f9f9;
    background-repeat: repeat-y;
    background-size: 50px 500px;
    background-position: 0 0;
    animation: shine 1s infinite;
  }
  
  @keyframes shine {
    to {
      background-position: 100% 0;
    }
  }

.order table {
    border: 1px solid #e1dfdf;
}

.order table th {
    border: 1px solid #e1dfdf;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 4px;
}

.order table td {
    border: 1px solid #e1dfdf;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 4px;
}

.myactive {
    background-color: #fbf8f8;
}

.dashboard {
    border: 1px solid #ddd;
}
.dashboard ul {
    padding: 15px;
    list-style: none;
}
.dashboard ul li {
    display: flex;
    -webkit-transition: all .5s ease;
    transition: all .5s ease;
}
.dashboard ul li a{
    -webkit-transition: .5s ease;
    transition: .5s ease;
}
.dashboard ul li::before {
    font-family: 'ElegantIcons';
    content: attr(data-icon);
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    width: 30px;
    height: 30px;
    text-align: center;
    font-weight: 700;
    margin-top: 3px;
}
.dashboard ul li:hover {
    padding-left: 10px;
    color: red;
    -webkit-transition: all .5s ease;
    transition: all .5s ease;
}
.dashboard-details {
    border: 1px solid #ddd;
    padding: 30px;
}
.error {
    color: red;
    font-size: 12px;
}

.card {
    padding: 10px;
    border-radius: 4px;
    border-radius: 6px;
    background-color: rgb(247, 247, 247);
    border: 1px solid rgb(247, 247, 247);
    font-weight: normal !important;
}

.card .address-type {
    font-family: Lato, sans-serif;
    font-size: 13px;
    font-weight: 700;
    color: rgb(13, 17, 54);
    line-height: 1.2;
    margin-bottom: 5px;
    text-transform: capitalize;
}

.customModal {
    max-width: 600px;
    width: 100%;
}
.tickets {
    list-style: none;
    margin: 0;
    padding: 0;
}
.tickets li {
    float: left;
    margin-right: 50px;
}
.ticket_table {
    clear: both;
    width: 100%;
    border: 0 !important;
    border-right: 0;
    margin-top: 50px;
}
.ticket_table th {
    border-right: 0 !important;
    border-left: 0 !important;
    color: #7b7a7a;
}
.ticket_table td {
    border-right: 0 !important;
    border-left: 0 !important;
    color: #7b7a7a;
    padding-top: 15px !important;
    padding-bottom: 15px !important;
}
.bottom0 {
    border-bottom: 0 !important;
}
.top0 {
    border-top: 0 !important;
}
.reply {
    font-size: 10px;
}