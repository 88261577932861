.box_contacts {
  background-color: #fff;
  padding: 30px 15px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  margin-bottom: 20px;
  text-align: center;
  font-size: 14px;
  font-size: 0.875rem;
  -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
  -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
  box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05);
}
.box_contacts h2 {
  font-weight: 400;
  margin: 10px 0 10px 0;
  font-size: 21px;
  font-size: 1.3125rem;
}
.box_contacts i {
  font-size: 46px;
  font-size: 2.875rem;
  color: #589442;
}
.box_contacts a {
  color: #444;
}
.box_contacts a:hover {
  color: #1a1a1a;
  text-decoration: underline;
}
.box_contacts small {
  display: block;
  color: #777;
}

iframe.map_contact {
  border: 0;
  width: 100%;
  height: 372px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  border-radius: 5px;
  overflow: hidden;
}
