.carousel_1 .item {
 -webkit-border-radius:5px;
 -moz-border-radius:5px;
 -ms-border-radius:5px;
 border-radius:5px;
 overflow:hidden;
 margin-bottom:10px
}
.box_booking {
 border:2px dotted #d2d8dd;
 -webkit-border-radius:5px;
 -moz-border-radius:5px;
 -ms-border-radius:5px;
 border-radius:5px;
 margin-bottom:25px;
 padding: 10px;
 background-color: #f0f0f0;
}
.box_booking .head {
 text-align:center;
 padding:23px 20px 18px 20px;
 background-color:#f8f8f8;
 border-bottom:2px dotted #d2d8dd
}
.box_booking .head h3 {
 font-size:24px;
 font-size:1.5rem;
 margin:0
}
.box_booking .head small {
 display:block
}
.box_booking .head .offer {
 color:#e74747;
 font-weight:500;
 font-size:16px;
 font-size:1rem
}
.box_booking .main {
 padding:25px
}
.box_booking .main #datepicker_field {
 display:none
}
.box_booking .form-group {
 margin:0 0 10px 0
}
.box_booking .form-group input,
.box_booking .form-group textarea {
 -webkit-border-radius:0;
 -moz-border-radius:0;
 -ms-border-radius:0;
 border-radius:0;
 border:1px dotted #ccc
}
.box_booking textarea {
 height:130px
}
.box_booking a.close_panel_mobile {
 position:absolute;
 right:10px;
 top:0;
 color:#333;
 font-size:26px;
 font-size:1.625rem;
 display:none
}
@media (max-width: 767px) {
 .box_booking.mobile_fixed {
  display:none;
  -webkit-border-radius:0;
  -moz-border-radius:0;
  -ms-border-radius:0;
  border-radius:0;
  position:fixed;
  overflow-y:scroll;
  border:0;
  left:0;
  top:0;
  width:100%;
  height:100%;
  z-index:999999;
  background-color:#fff
 }
 .box_booking.mobile_fixed .head {
  padding:10px 20px 10px 20px;
  position:relative
 }
 .box_booking.mobile_fixed .head h3 {
  font-size:18px;
  font-size:1.125rem;
  text-transform:uppercase
 }
 .box_booking.mobile_fixed .head .offer {
  display:none
 }
 .box_booking.mobile_fixed .head a.close_panel_mobile {
  display:inline-block
 }
 .box_booking.mobile_fixed .main {
  padding-bottom:50px
 }
 .box_booking.mobile_fixed .btn_1_mobile {
  position:fixed;
  left:0;
  bottom:0;
  width:100%;
  padding:5px;
  background-color:#fff;
  border-top:1px solid #ededed;
  z-index:9999999
 }
 .box_booking.mobile_fixed .btn_1_mobile .text-center {
  display:none
 }
}
.box_booking a.close_panel_mobile {
 position:absolute;
 right:10px;
 top:0;
 color:#333;
 font-size:26px;
 font-size:1.625rem;
 display:none
}
@media (max-width: 767px) {
 .box_booking.mobile_fixed {
  display:none;
  -webkit-border-radius:0;
  -moz-border-radius:0;
  -ms-border-radius:0;
  border-radius:0;
  position:fixed;
  overflow-y:scroll;
  border:0;
  left:0;
  top:0;
  width:100%;
  height:100%;
  z-index:999999;
  background-color:#fff
 }
 .box_booking.mobile_fixed .head {
  padding:10px 20px 10px 20px;
  position:relative
 }
 .box_booking.mobile_fixed .head h3 {
  font-size:18px;
  font-size:1.125rem;
  text-transform:uppercase
 }
 .box_booking.mobile_fixed .head .offer {
  display:none
 }
 .box_booking.mobile_fixed .head a.close_panel_mobile {
  display:inline-block
 }
 .box_booking.mobile_fixed .main {
  padding-bottom:50px
 }
 .box_booking.mobile_fixed .btn_1_mobile {
  position:fixed;
  left:0;
  bottom:0;
  width:100%;
  padding:10px;
  background-color:#fff;
  border-top:1px solid #ededed;
  z-index:9999999
 }
 .box_booking.mobile_fixed .btn_1_mobile .text-center {
  display:none
 }
}
.btn_reserve_fixed {
 position:fixed;
 left:0;
 bottom:0;
 width:100%;
 padding:10px;
 background-color:#fff;
 border-top:1px solid #ededed;
 display:none;
 z-index:9
}
@media (max-width: 767px) {
 .btn_reserve_fixed {
  display:block
 }
}
/*! jQuery UI - v1.10.4
* http://jqueryui.com

/* Layout helpers
----------------------------------*/.ui-helper-clearfix:before,
.ui-helper-clearfix:after {
 content:"";
 display:table;
 border-collapse:collapse
}
.ui-helper-clearfix:after {
 clear:both
}
.ui-helper-clearfix {
 min-height:0
}
.ui-state-disabled {
 cursor:default !important
}
.ui-icon {
 display:block;
 text-indent:-99999px;
 overflow:hidden;
 background-repeat:no-repeat
}
.ui-datepicker {
 width:100%;
 display:none
}
.ui-datepicker .ui-datepicker-header {
 position:relative;
 padding:.2em 0
}
.ui-datepicker .ui-datepicker-prev,
.ui-datepicker .ui-datepicker-next {
 position:absolute;
 top:-4px;
 width:1.8em;
 height:1.8em
}
.ui-datepicker .ui-datepicker-prev:before,
.ui-datepicker .ui-datepicker-next:before {
 font-family:'ElegantIcons';
 font-size:26px;
 font-size:1.625rem
}
.ui-datepicker .ui-datepicker-prev:before {
 content:"\34"
}
.ui-datepicker .ui-datepicker-next:before {
 content:"\35"
}
.ui-datepicker .ui-datepicker-prev {
 left:2px
}
.ui-datepicker .ui-datepicker-next {
 right:2px
}
.ui-datepicker .ui-datepicker-prev-hover {
 background-color:transparent !important
}
.ui-datepicker .ui-datepicker-next-hover {
 background-color:transparent !important
}
.ui-datepicker .ui-datepicker-title {
 margin:0 2.3em;
 line-height:1.8em;
 text-align:center
}
.ui-datepicker table {
 width:100%;
 font-size:14px;
 border-collapse:collapse;
 margin:0 0 .4em
}
.ui-datepicker th {
 padding:.7em .3em;
 text-align:center;
 font-weight:400;
 border:0
}
.ui-datepicker td {
 border:0;
 padding:1px
}
.ui-datepicker td span,
.ui-datepicker td a {
 display:block;
 padding:2px 5px 12px 5px;
 text-align:center;
 text-decoration:none
}
.ui-widget {
 font-size:1.1em
}
.ui-widget .ui-widget {
 font-size:1em
}
.ui-widget-content {
 color:#362b36
}
.ui-widget-content a {
 color:#362b36
}
.ui-widget-header {
 color:#222222;
 font-weight:500
}
.ui-widget-header a {
 color:#222222
}
.ui-state-default,
.ui-widget-content .ui-state-default,
.ui-widget-header .ui-state-default {
 border:1px dotted #ccc;
 font-weight:600;
 color:#444
}
.ui-state-default a,
.ui-state-default a:link,
.ui-state-default a:visited {
 color:#2779aa;
 text-decoration:none
}
.ui-state-hover,
.ui-widget-content .ui-state-hover,
.ui-widget-header .ui-state-hover,
.ui-state-focus,
.ui-widget-content .ui-state-focus,
.ui-widget-header .ui-state-focus {
 background-color:#dfd
}
.ui-state-active,
.ui-widget-content .ui-state-active,
.ui-widget-header .ui-state-active {
 background:#589442 !important;
 color:#fff !important
}
.ui-state-active:after,
.ui-widget-content .ui-state-active:after,
.ui-widget-header .ui-state-active:after {
 color:#ffff66
}
.ui-state-active a,
.ui-state-active a:link,
.ui-state-active a:visited {
 color:#ffffff;
 text-decoration:none
}
.ui-state-highlight,
.ui-widget-content .ui-state-highlight,
.ui-widget-header .ui-state-highlight {
 background:#dfd;
 color:#444
}
.ui-state-disabled,
.ui-widget-content .ui-state-disabled,
.ui-widget-header .ui-state-disabled {
 opacity:.35;
 filter:Alpha(Opacity=35);
 background-image:none
}
.ui-state-disabled .ui-icon {
 filter:Alpha(Opacity=35)
}
.ui-datepicker td a {
 position:relative
}
.ui-datepicker td a:after {
 display:block;
 color:red;
 font-size:11px;
 font-size:0.6875rem;
 position:absolute;
 bottom:0;
 left:0;
 font-weight:500;
 width:100%
}
.tabs_detail {
 margin-top:15px
}
.tabs_detail .nav-tabs {
 border:none;
 border-bottom:2px solid #ededed;
 display:flex
}
@media (max-width: 767px) {
 .tabs_detail .nav-tabs {
  display:none
 }
}
.tabs_detail .nav-tabs .nav-item {
 text-transform:uppercase;
 font-weight:500
}
.tabs_detail .tab-content .card {
 border:none;
 background-color:transparent
}
.tabs_detail .tab-content .card .card-header {
 padding:0;
 background-color:transparent;
 border:0;
 background-color:#f8f8f8
}
.tabs_detail .tab-content .card .card-header a {
 display:block;
 font-size:18px;
 font-size:1.125rem;
 padding:15px;
 color:#222
}
.tabs_detail .tab-content .card .card-body {
 padding:45px 0 25px 0
}
@media (max-width: 991px) {
 .tabs_detail .tab-content .card .card-body {
  padding:25px 0 5px 0
 }
}
@media (max-width: 991px) {
 .tabs_detail .tab-content .card .card-body.reviews .add_bottom_45 {
  padding-bottom:25px
 }
}
@media (min-width: 768px) {
 .tabs_detail .tab-content .card {
  border:none
 }
 .tabs_detail .tab-content .card .card-header {
  display:none
 }
 .tabs_detail .tab-content .card .collapse {
  display:block
 }
}
@media (max-width: 767px) {
 .tabs_detail .tab-content {
  margin-bottom:25px
 }
 .tabs_detail .tab-content .card-header a:after {
  font-family:'ElegantIcons';
  width:20px;
  display:block;
  float:right
 }
 .tabs_detail .tab-content .card-header a.collapsed:after {
  content:"\50"
 }
 .tabs_detail .tab-content .card-header a:after {
  content:"\4f"
 }
 .tabs_detail .tab-content .fade:not(.show) {
  opacity:1
 }
 .tabs_detail .tab-content .tab-pane {
  display:block !important;
  opacity:1
 }
}
ul.share-buttons {
 padding:0;
 list-style:none;
 text-align:center;
 margin:0 0 25px 0
}
ul.share-buttons li {
 display:inline-block;
 margin:0 5px 5px 5px
}
ul.share-buttons li a {
 background:#fff;
 border:1px solid #ededed;
 -webkit-border-radius:3px;
 -moz-border-radius:3px;
 -ms-border-radius:3px;
 border-radius:3px;
 font-weight:500;
 font-size:13px;
 font-size:0.8125rem;
 padding:7px 20px;
 transition:0.3s;
 display:inline-block;
 line-height:17px;
 font-weight:500
}
ul.share-buttons li a:hover {
 color:#fff
}
ul.share-buttons li a.fb-share {
 color:#3b5998
}
ul.share-buttons li a.fb-share:hover {
 background:#3b5998;
 color:#fff;
 border-color:#3b5998
}
ul.share-buttons li a.gplus-share {
 color:#dd4b39
}
ul.share-buttons li a.gplus-share:hover {
 background:#dd4b39;
 color:#fff;
 border-color:#dd4b39
}
ul.share-buttons li a.twitter-share {
 color:#1da1f2
}
ul.share-buttons li a.twitter-share:hover {
 background:#1da1f2;
 color:#fff;
 border-color:#1da1f2
}
ul.share-buttons li i {
 font-size:16px;
 font-size:1rem;
 position:relative;
 right:3px;
 top:2px
}
.info_content h2 {
 text-align:center;
 font-weight:500;
 margin-bottom:20px;
 font-size:24px;
 font-size:1.5rem
}
.info_content h3 {
 font-weight:500;
 margin-bottom:15px;
 font-size:18px;
 font-size:1.125rem
}
.info_content hr {
 margin-top:25px;
 margin-bottom:25px
}
.info_content .menu_item h4 {
 font-weight:500;
 margin-bottom:0;
 font-size:14px;
 font-size:0.875rem
}
.info_content .menu_item em {
 float:right
}
.info_content .menu_item p {
 color:#777
}
.menu_item.thumbs {
 padding-left:100px;
 position:relative;
 min-height:100px;
 display:flex;
 align-items:center
}
.menu_item.thumbs h4 {
 margin-bottom:5px
}
.menu_item.thumbs figure {
 width:80px;
 height:80px;
 overflow:hidden;
 -webkit-border-radius:3px;
 -moz-border-radius:3px;
 -ms-border-radius:3px;
 border-radius:3px;
 position:absolute;
 left:0;
 top:0
}
.menu_item.thumbs figure img {
 position:absolute;
 left:50%;
 top:50%;
 width:100px;
 height:auto;
 -webkit-transform:translate(-50%, -50%);
 -moz-transform:translate(-50%, -50%);
 -ms-transform:translate(-50%, -50%);
 -o-transform:translate(-50%, -50%);
 transform:translate(-50%, -50%);
 z-index:1
}
.menu_item.thumbs>div {
 width:100%
}
@media (max-width: 767px) {
 .menu_item.thumbs>div em {
  float:none
 }
}
.menu_item.thumbs p {
 padding-right:80px;
 line-height:1.4
}
@media (max-width: 767px) {
 .menu_item.thumbs p {
  font-size:13px;
  font-size:0.8125rem;
  padding-right:0
 }
}
.pictures {
 margin-bottom:45px;
 text-align:center
}
.pictures figure {
 margin:0;
 overflow:hidden;
 position:relative;
 height:120px;
 width:120px;
 display:inline-block
}
@media (max-width: 767px) {
 .pictures figure {
  width:80px;
  height:80px
 }
}
.pictures figure a {
 display:block
}
.pictures figure a span {
 display:block;
 width:100%;
 height:100%;
 position:absolute;
 top:0;
 left:0;
 z-index:9;
 background-color:#000;
 background-color:rgba(0,0,0,0.7);
 color:#fff;
 font-size:26px;
 font-size:1.625rem
}
.pictures figure a img {
 position:absolute;
 left:50%;
 top:50%;
 -webkit-transform:translate(-50%, -50%) scale(1);
 -moz-transform:translate(-50%, -50%) scale(1);
 -ms-transform:translate(-50%, -50%) scale(1);
 -o-transform:translate(-50%, -50%) scale(1);
 transform:translate(-50%, -50%) scale(1);
 -webkit-backface-visibility:hidden;
 -moz-backface-visibility:hidden;
 -ms-backface-visibility:hidden;
 -o-backface-visibility:hidden;
 backface-visibility:hidden;
 width:100%;
 z-index:1;
 -moz-transition:all 0.3s ease-in-out;
 -o-transition:all 0.3s ease-in-out;
 -webkit-transition:all 0.3s ease-in-out;
 -ms-transition:all 0.3s ease-in-out;
 transition:all 0.3s ease-in-out
}
.pictures figure a:hover img {
 -webkit-transform:translate(-50%, -50%) scale(1.05);
 -moz-transform:translate(-50%, -50%) scale(1.05);
 -ms-transform:translate(-50%, -50%) scale(1.05);
 -o-transform:translate(-50%, -50%) scale(1.05);
 transform:translate(-50%, -50%) scale(1.05)
}
a.show_hide:focus {
 color:#589442
}
a.show_hide:focus:hover {
 color:#111
}
.special_offers h2 {
 color:#e74747
}
.special_offers h2:after,
.special_offers h2:before {
 display:inline-block;
 width:50px;
 height:1px;
 content:'';
 vertical-align:middle;
 background:#e74747;
 margin:0 8px
}
.reviews #review_summary {
 text-align:center;
 background-color:#1f2f6a;
 color:#fff;
 padding:20px 10px;
 -webkit-border-radius:5px;
 -moz-border-radius:5px;
 -ms-border-radius:5px;
 border-radius:5px
}
@media (max-width: 991px) {
 .reviews #review_summary {
  margin-bottom:15px
 }
}
.reviews #review_summary strong {
 font-size:42px;
 font-size:2.625rem;
 display:block;
 line-height:1
}
.reviews #review_summary em {
 font-style:normal;
 font-weight:500;
 display:block
}
.reviews .progress {
 margin-bottom:12px;
 height:5px
}
.reviews .progress-bar {
 background-color:#589442
}
.reviews .reviews_sum_details h6 {
 font-size:14px;
 font-size:0.875rem
}
.reviews .reviews_sum_details strong {
 position:relative;
 top:-8px
}
.review_card {
 border:1px solid #ededed;
 -webkit-border-radius:3px;
 -moz-border-radius:3px;
 -ms-border-radius:3px;
 border-radius:3px;
 padding:25px 25px 10px 25px;
 margin-bottom:30px;
 -webkit-box-shadow:0 2px 4px rgba(3,27,78,0.06);
 -moz-box-shadow:0 2px 4px rgba(3,27,78,0.06);
 box-shadow:0 2px 4px rgba(3,27,78,0.06)
}
.review_card .user_info {
 text-align:center
}
@media (max-width: 767px) {
 .review_card .user_info {
  margin-bottom:10px
 }
}
.review_card .user_info figure {
 width:50px;
 height:50px;
 overflow:hidden;
 -webkit-border-radius:50%;
 -moz-border-radius:50%;
 -ms-border-radius:50%;
 border-radius:50%;
 display:inline-block;
 position:relative;
 margin-bottom:10px
}
@media (max-width: 767px) {
 .review_card .user_info figure {
  float:left
 }
}
.review_card .user_info figure img {
 width:50px;
 height:auto;
 position:absolute;
 left:50%;
 top:50%;
 -webkit-transform:translate(-50%, -50%);
 -moz-transform:translate(-50%, -50%);
 -ms-transform:translate(-50%, -50%);
 -o-transform:translate(-50%, -50%);
 transform:translate(-50%, -50%)
}
.review_card .user_info h5 {
 font-size:13px;
 font-size:0.8125rem
}
@media (max-width: 767px) {
 .review_card .user_info h5 {
  float:left;
  margin:18px 0 0 15px
 }
}
.review_card .review_content h4 {
 font-size:18px;
 font-size:1.125rem
}
.review_card .review_content .rating {
 float:left;
 font-weight:600;
 font-size:26px;
 font-size:1.625rem;
 color:#589442
}
.review_card .review_content .rating small {
 font-size:12px;
 font-size:0.75rem;
 font-weight:600
}
.review_card .review_content .rating strong {
 font-size:13px;
 font-size:0.8125rem;
 font-weight:400;
 color:#444
}
@media (max-width: 575px) {
 .review_card .review_content .rating {
  float:none;
  display:block
 }
}
@media (max-width: 575px) {
 .review_card .review_content .rating em {
  float:none;
  display:inline-block;
  margin-left:10px
 }
}
.review_card .review_content em {
 color:#999;
 float:right
}
@media (max-width: 575px) {
 .review_card .review_content em {
  float:none;
  display:block;
  margin-top:10px;
  margin-left:0
 }
}
.review_card .review_content ul {
 margin:35px 0 25px 0;
 padding:0
}
.review_card .review_content ul li {
 display:inline-block;
 font-size:13px;
 font-size:0.8125rem;
 margin-right:0
}
@media (max-width: 575px) {
 .review_card .review_content ul li span {
  display:none
 }
}
.review_card .review_content ul li a {
 background-color:#f0f0f0;
 padding:7px 10px 5px 10px;
 -webkit-border-radius:3px;
 -moz-border-radius:3px;
 -ms-border-radius:3px;
 border-radius:3px;
 color:#444
}
.review_card .review_content ul li a:hover {
 background-color:#589442;
 color:#fff
}
.review_card .review_content ul li a i {
 margin-right:5px
}
@media (max-width: 575px) {
 .review_card .review_content ul li a i {
  margin-right:0
 }
}
.review_card .review_content ul li:last-child {
 float:right;
 margin-right:0
}
.review_card .reply {
 padding-top:30px
}
@media (max-width: 767px) {
 .review_card .reply .user_info {
  display:none
 }
}
.review_card .reply .user_info figure {
 width:50px;
 height:50px;
 overflow:hidden;
 -webkit-border-radius:50%;
 -moz-border-radius:50%;
 -ms-border-radius:50%;
 border-radius:50%;
 display:inline-block;
 position:relative;
 margin-bottom:10px
}
.review_card .reply .user_info figure img {
 width:50px;
 height:auto;
 position:absolute;
 left:50%;
 top:50%;
 -webkit-transform:translate(-50%, -50%);
 -moz-transform:translate(-50%, -50%);
 -ms-transform:translate(-50%, -50%);
 -o-transform:translate(-50%, -50%);
 transform:translate(-50%, -50%)
}
.review_card .reply .user_info h5 {
 font-size:14px;
 font-size:0.875rem
}
.review_card .reply .review_content {
 border-left:5px solid #ededed;
 padding-left:20px
}
@media (max-width: 575px) {
 .review_card .reply .review_content strong {
  display:block
 }
}
.review_card .reply .review_content em {
 color:#999;
 font-style:italic;
 float:right
}
@media (max-width: 575px) {
 .review_card .reply .review_content em {
  float:none;
  display:block;
  margin-top:10px
 }
}
.dropdown {
 border:1px dotted #c8c8c8;
 padding:0;
 margin-bottom:5px
}
.dropdown a {
 padding:15px 15px 12px 15px;
 line-height:1;
 color:#444;
 font-weight:500;
 display:block;
 position:relative
}
.dropdown a:after {
 font-family:'food';
 font-size:21px;
 font-size:1.3125rem;
 font-weight:bold;
 -moz-transition:all 0.3s ease-in-out;
 -o-transition:all 0.3s ease-in-out;
 -webkit-transition:all 0.3s ease-in-out;
 -ms-transition:all 0.3s ease-in-out;
 transition:all 0.3s ease-in-out;
 position:absolute;
 right:10px;
 top:10px
}
.dropdown.show a {
 color:#333
}
.dropdown.show a:after {
 transform:rotateY(360deg);
 color:#333
}
.dropdown .dropdown-menu {
 font-size:14px;
 font-size:0.875rem;
 background:#f8f8f8;
 border:1px solid #ddd;
 -webkit-border-radius:0;
 -moz-border-radius:0;
 -ms-border-radius:0;
 border-radius:0;
 padding:15px;
 width:100.5%;
 left:-1px !important;
 right:-1px !important;
 margin:5px 0 0 0
}
.dropdown .dropdown-menu h4 {
 text-align:center;
 font-size:14px;
 font-size:0.875rem;
 text-transform:uppercase
}
.dropdown.time a:after {
 content:'\0057'
}
.dropdown.people {
 margin-bottom:25px
}
.dropdown.people a:after {
 content:'\0059'
}
.radio_select ul {
 list-style:none;
 padding:0;
 margin:0;
 width:100%;
 text-align:center
}
.radio_select ul li {
 display:inline-block;
 margin:0;
 width:23.5%
}
.radio_select input[type="radio"] {
 opacity:0;
 position:fixed;
 width:0
}
.radio_select label {
 display:inline-block;
 text-align:center;
 width:100%;
 padding:8px;
 border:1px dotted #c8c8c8;
 background-color:#fff;
 font-size:16px;
 font-size:1rem
}
.radio_select label em {
 display:block;
 color:red;
 font-style:normal;
 font-size:11px;
 font-size:0.6875rem;
 font-weight:500
}
.radio_select label:hover {
 background-color:#dfd
}
.radio_select input[type="radio"]:focus+label {
 border:1px solid transparent;
 background-color:#589442;
 color:#fff
}
.radio_select input[type="radio"]:focus+label em {
 color:#ffff66
}
.radio_select input[type="radio"]:checked+label {
 border:1px solid transparent;
 background-color:#589442;
 color:#fff
}
.radio_select input[type="radio"]:checked+label em {
 color:#ffff66
}
.other_info {
 background-color:#f9f9f9;
 padding:25px 25px 5px 25px;
 margin-bottom:20px
}
.other_info h2 {
 margin-bottom:35px
}
.follow_us_detail a {
 color:#999;
 display:inline-block;
 margin:5px 5px 0 0
}
.follow_us_detail a:hover {
 color:#589442
}
.follow_us_detail a i {
 font-size:24px;
 font-size:1.5rem
}

.box_booking_2, .sign_up {
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    border-radius: 5px;
    margin-bottom: 25px;
    background-color: #fff;
    -webkit-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.3);
    overflow: hidden;
  }
  .box_booking_2 .head, .sign_up .head {
    text-align: center;
    padding: 23px 20px 18px 20px;
    background-color: #589442;
    color: #fff;
  }
  .box_booking_2 .head h3, .sign_up .head h3 {
    font-size: 24px;
    font-size: 1.5rem;
    margin: 0;
    color: #fff;
  }
  .box_booking_2 .head small, .sign_up .head small {
    display: block;
  }
  .box_booking_2 .head a, .sign_up .head a {
    color: #fff;
    text-decoration: underline;
  }
  .box_booking_2 .main, .sign_up .main {
    padding: 25px;
  }
  .box_booking_2 .main ul, .sign_up .main ul {
    list-style: none;
    padding: 0;
  }
  .box_booking_2 .main ul li, .sign_up .main ul li {
    font-weight: 500;
    margin-bottom: 5px;
  }
  .box_booking_2 .main ul li span, .sign_up .main ul li span {
    float: right;
    font-weight: 400;
  }
  .box_booking_2 .main ul li.total, .sign_up .main ul li.total {
    font-size: 16px;
    font-size: 1rem;
    font-weight: 600;
    color: #e74747;
    text-transform: uppercase;
  }
  .box_booking_2 .main ul li.total span, .sign_up .main ul li.total span {
    font-weight: 600;
  }
  .box_booking_2 .form-group input, .sign_up .form-group input {
    padding-right: 40px;
  }
  .box_booking_2 .form-group i, .sign_up .form-group i {
    font-size: 16px;
    font-size: 1rem;
    position: absolute;
    right: 3px;
    top: 12px;
    color: #ccc;
    width: 25px;
    height: 25px;
    display: block;
    font-weight: 400 !important;
  }
  .box_booking_2 .form-group i.icon_mail, .sign_up .form-group i.icon_mail {
    top: 10px;
  }
  
  .box_booking_2.style_2 .head {
    text-align: left;
    padding: 15px 20px 12px 25px;
  }
  .box_booking_2.style_2 .head h3 {
    font-size: 21px;
    font-size: 1.3125rem;
  }
  .box_booking_2.style_2 .main {
    padding: 20px 25px 10px 25px;
  }
  .box_booking_2.style_2 .form-group input {
    padding-right: 10px;
  }
  
  .payment_select {
    background-color: #f0f0f0;
    padding: 15px 15px 10px 15px;
    margin-bottom: 20px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
  }
  .payment_select label {
    font-size: 16px;
    display: inline-block;
    margin-bottom: 0;
  }
  .payment_select i {
    float: right;
    font-size: 24px;
  }
  .payment_select#paypal {
    background: #f0f0f0 center right no-repeat;
  }
  
  #confirm {
    text-align: center;
    padding: 60px 15px;
  }
  
  @-webkit-keyframes checkmark {
    0% {
      stroke-dashoffset: 50px;
    }
    100% {
      stroke-dashoffset: 0;
    }
  }
  @-ms-keyframes checkmark {
    0% {
      stroke-dashoffset: 50px;
    }
    100% {
      stroke-dashoffset: 0;
    }
  }
  @keyframes checkmark {
    0% {
      stroke-dashoffset: 50px;
    }
    100% {
      stroke-dashoffset: 0;
    }
  }
  @-webkit-keyframes checkmark-circle {
    0% {
      stroke-dashoffset: 240px;
    }
    100% {
      stroke-dashoffset: 480px;
    }
  }
  @-ms-keyframes checkmark-circle {
    0% {
      stroke-dashoffset: 240px;
    }
    100% {
      stroke-dashoffset: 480px;
    }
  }
  @keyframes checkmark-circle {
    0% {
      stroke-dashoffset: 240px;
    }
    100% {
      stroke-dashoffset: 480px;
    }
  }
  .inlinesvg .svg svg {
    display: inline;
  }
  
  .icon--order-success svg path {
    -webkit-animation: checkmark 0.25s ease-in-out 0.7s backwards;
    animation: checkmark 0.25s ease-in-out 0.7s backwards;
  }
  
  .icon--order-success svg circle {
    -webkit-animation: checkmark-circle 0.6s ease-in-out backwards;
    animation: checkmark-circle 0.6s ease-in-out backwards;
  }
  /*============================================================================================*/
/* Detail page */
/*============================================================================================*/
/*-------- Page head --------*/
.box_booking {
    border: 2px dotted #d2d8dd;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    border-radius: 5px;
    margin-bottom: 25px;
    background-color: rgb(251 251 251);
  }
  .box_booking .head {
    text-align: center;
    padding: 23px 20px 18px 20px;
    background-color: #f8f8f8;
    border-bottom: 2px dotted #d2d8dd;
  }
  .box_booking .head h3 {
    font-size: 24px;
    font-size: 1.5rem;
    margin: 0;
  }
  .box_booking .head small {
    display: block;
  }
  .box_booking .head .offer {
    color: #e74747;
    font-weight: 500;
    font-size: 16px;
    font-size: 1rem;
  }
  .box_booking .main {
    padding: 25px;
  }
  .box_booking .main ul {
    list-style: none;
    padding: 0;
  }
  .box_booking .main ul li {
    margin-bottom: 8px;
  }
  .box_booking .main ul li.total {
    font-size: 18px;
    font-size: 1.125rem;
    font-weight: 600;
    color: #e74747;
    text-transform: uppercase;
  }
  .box_booking .main ul li a {
    padding-left: 0px;
    color: #444;
    display: inline-block;
    position: relative;
  }

  .box_booking .main ul li a:hover {
    color: #e74747;
  }
  .box_booking .main ul li span {
    float: right;
    font-weight: 500;
  }
  .box_booking .form-group {
    margin: 0 0 10px 0;
  }
  .box_booking textarea {
    height: 130px;
  }
  
  .opt_order {
    border-top: 1px solid #ededed;
    border-bottom: 1px solid #ededed;
    margin-bottom: 20px;
    padding: 15px 0 5px 0;
  }
  
  .tabs_detail {
    margin-top: 15px;
  }
  .tabs_detail .nav-tabs {
    border: none;
    border-bottom: 2px solid #ededed;
    display: flex;
  }
  @media (max-width: 767px) {
    .tabs_detail .nav-tabs {
      display: none;
    }
  }
  .tabs_detail .nav-tabs .nav-item {
    text-transform: uppercase;
    font-weight: 500;
  }
  .tabs_detail .tab-content .card {
    border: none;
    background-color: transparent;
  }
  .tabs_detail .tab-content .card .card-header {
    padding: 0;
    background-color: transparent;
    border: 0;
    background-color: #f8f8f8;
  }
  .tabs_detail .tab-content .card .card-header a {
    display: block;
    font-size: 18px;
    font-size: 1.125rem;
    padding: 15px;
    color: #222;
  }
  .tabs_detail .tab-content .card .card-body {
    padding: 45px 0 25px 0;
  }
  @media (max-width: 991px) {
    .tabs_detail .tab-content .card .card-body {
      padding: 25px 0 5px 0;
    }
  }
  @media (max-width: 991px) {
    .tabs_detail .tab-content .card .card-body.reviews .add_bottom_45 {
      padding-bottom: 25px;
    }
  }
  @media (min-width: 768px) {
    .tabs_detail .tab-content .card {
      border: none;
    }
    .tabs_detail .tab-content .card .card-header {
      display: none;
    }
    .tabs_detail .tab-content .card .collapse {
      display: block;
    }
  }
  @media (max-width: 767px) {
    .tabs_detail .tab-content {
      margin-bottom: 25px;
    }
    .tabs_detail .tab-content .card-header a:after {
      font-family: 'ElegantIcons';
      width: 20px;
      display: block;
      float: right;
    }
    .tabs_detail .tab-content .card-header a.collapsed:after {
      content: "\50";
    }
    .tabs_detail .tab-content .card-header a:after {
      content: "\4f";
    }
    .tabs_detail .tab-content .fade:not(.show) {
      opacity: 1;
    }
    .tabs_detail .tab-content .tab-pane {
      display: block !important;
      opacity: 1;
    }
  }
  
  /*-------- Menu list --------*/
  .table.cart-list {
    margin-bottom: 30px;
    border-bottom: 1px solid #ededed;
  }
  .table.cart-list.table-striped tbody tr:nth-of-type(odd) {
    background-color: #f8f8f8;
  }
  .table.cart-list thead th {
    border: none;
    font-weight: 500;
  }
  .table.cart-list td {
    vertical-align: middle;
  }
  .table.cart-list td.options i {
    font-size: 26px;
  }
  .table.cart-list td h4 {
    margin: 0;
    font-size: 16px;
    font-size: 1rem;
  }
  .table.cart-list td p {
    margin: 0;
    font-size: 13px;
    font-size: 0.8125rem;
    color: #444;
  }
  .table.cart-list td figure {
    width: 60px;
    height: 60px;
    overflow: hidden;
    display: inline-block;
    float: left;
    margin: 0;
    margin-right: 15px;
    border: 1px solid #fff;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
  }
  .table.cart-list td figure img {
    width: 60px;
    height: auto;
  }
  @media (max-width: 767px) {
    .table.cart-list td figure {
      float: none;
    }
  }
  .table.cart-list td .dropdown-options {
    border: none;
    margin: 0;
  }
  .table.cart-list td .dropdown-options > a {
    padding: 0;
    display: inline-block;
    color: #589442;
  }
  .table.cart-list td .dropdown-options > a:after {
    display: none;
  }
  .table.cart-list td .dropdown-options > a:hover {
    color: #111;
  }
  .table.cart-list td .dropdown-menu {
    background-color: #fff;
    min-width: 250px;
    margin-left: -105px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
  }
  @media (max-width: 991px) {
    .table.cart-list td .dropdown-menu {
      margin-left: -205px;
    }
  }
  .table.cart-list td .dropdown-menu label {
    font-weight: normal;
  }
  .table.cart-list td .dropdown-menu a {
    color: #fff;
  }
  .table.cart-list td .dropdown-menu h5 {
    border-bottom: 1px solid #ededed;
    padding-bottom: 10px;
    margin-bottom: 10px !important;
    font-size: 14px;
    font-size: 0.875rem;
  }
  .table.cart-list td .dropdown-menu ul {
    list-style: none;
    padding: 0;
  }
  .table.cart-list td .dropdown-menu ul li label small {
    float: right;
    padding-top: 3px;
  }
  
  #message {
    position: fixed;
    bottom: 15px;
    left: 15px;
    background-color: #589442;
    color: #fff;
    padding: 12px 12px 10px 40px;
    font-weight: 500;
    line-height: 1;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    border-radius: 5px;
    -webkit-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.3);
    -moz-box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.3);
    box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.3);
    display: none;
  }
  #message:before {
    font-family: "ElegantIcons";
    content: "\52";
    font-size: 21px;
    font-size: 1.3125rem;
    position: absolute;
    left: 10px;
    top: 7px;
    line-height: 1;
  }
  
  ul.share-buttons {
    padding: 0;
    list-style: none;
    text-align: center;
    margin: 0 0 25px 0;
  }
  ul.share-buttons li {
    display: inline-block;
    margin: 0 5px 5px 5px;
  }
  ul.share-buttons li a {
    background: #fff;
    border: 1px solid #ededed;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
    font-weight: 500;
    font-size: 13px;
    font-size: 0.8125rem;
    padding: 7px 20px;
    transition: 0.3s;
    display: inline-block;
    line-height: 17px;
    font-weight: 500;
  }
  ul.share-buttons li a:hover {
    color: #fff;
  }
  ul.share-buttons li a.fb-share {
    color: #3b5998;
  }
  ul.share-buttons li a.fb-share:hover {
    background: #3b5998;
    color: #fff;
    border-color: #3b5998;
  }
  ul.share-buttons li a.gplus-share {
    color: #dd4b39;
  }
  ul.share-buttons li a.gplus-share:hover {
    background: #dd4b39;
    color: #fff;
    border-color: #dd4b39;
  }
  ul.share-buttons li a.twitter-share {
    color: #1da1f2;
  }
  ul.share-buttons li a.twitter-share:hover {
    background: #1da1f2;
    color: #fff;
    border-color: #1da1f2;
  }
  ul.share-buttons li i {
    font-size: 16px;
    font-size: 1rem;
    position: relative;
    right: 3px;
    top: 2px;
  }
  
  .info_content h2 {
    text-align: center;
    font-weight: 500;
    margin-bottom: 20px;
    font-size: 24px;
    font-size: 1.5rem;
  }
  .info_content h3 {
    font-weight: 500;
    margin-bottom: 15px;
    font-size: 18px;
    font-size: 1.125rem;
  }
  .info_content hr {
    margin-top: 25px;
    margin-bottom: 25px;
  }
  .info_content .menu_item h4 {
    font-weight: 500;
    margin-bottom: 0;
    font-size: 14px;
    font-size: 0.875rem;
  }
  .info_content .menu_item em {
    float: right;
  }
  .info_content .menu_item p {
    color: #777;
  }
  
  .pictures {
    margin-bottom: 45px;
    text-align: center;
  }
  .pictures figure {
    margin: 0;
    overflow: hidden;
    position: relative;
    height: 120px;
    width: 120px;
    display: inline-block;
  }
  @media (max-width: 767px) {
    .pictures figure {
      width: 80px;
      height: 80px;
    }
  }
  .pictures figure a {
    display: block;
  }
  .pictures figure a span {
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9;
    background-color: black;
    background-color: rgba(0, 0, 0, 0.7);
    color: #fff;
    font-size: 26px;
    font-size: 1.625rem;
  }
  .pictures figure a img {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%) scale(1);
    -moz-transform: translate(-50%, -50%) scale(1);
    -ms-transform: translate(-50%, -50%) scale(1);
    -o-transform: translate(-50%, -50%) scale(1);
    transform: translate(-50%, -50%) scale(1);
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    -o-backface-visibility: hidden;
    backface-visibility: hidden;
    width: 100%;
    z-index: 1;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  .pictures figure a:hover img {
    -webkit-transform: translate(-50%, -50%) scale(1.05);
    -moz-transform: translate(-50%, -50%) scale(1.05);
    -ms-transform: translate(-50%, -50%) scale(1.05);
    -o-transform: translate(-50%, -50%) scale(1.05);
    transform: translate(-50%, -50%) scale(1.05);
  }
  
  a.show_hide:focus {
    color: #589442;
  }
  a.show_hide:focus:hover {
    color: #111;
  }
  
  .special_offers h2 {
    color: #e74747;
  }
  .special_offers h2:after, .special_offers h2:before {
    display: inline-block;
    width: 50px;
    height: 1px;
    content: '';
    vertical-align: middle;
    background: #e74747;
    margin: 0 8px;
  }
  
  .reviews #review_summary {
    text-align: center;
    background-color: #1f2f6a;
    color: #fff;
    padding: 20px 10px;
    -webkit-border-radius: 5px;
    -moz-border-radius: 5px;
    -ms-border-radius: 5px;
    border-radius: 5px;
  }
  @media (max-width: 991px) {
    .reviews #review_summary {
      margin-bottom: 15px;
    }
  }
  .reviews #review_summary strong {
    font-size: 42px;
    font-size: 2.625rem;
    display: block;
    line-height: 1;
  }
  .reviews #review_summary em {
    font-style: normal;
    font-weight: 500;
    display: block;
  }
  .reviews .progress {
    margin-bottom: 12px;
    height: 5px;
  }
  .reviews .progress-bar {
    background-color: #589442;
  }
  .reviews .reviews_sum_details h6 {
    font-size: 14px;
    font-size: 0.875rem;
  }
  .reviews .reviews_sum_details strong {
    position: relative;
    top: -8px;
  }
  
  .review_card {
    border: 1px solid #ededed;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
    padding: 25px 25px 10px 25px;
    margin-bottom: 30px;
    -webkit-box-shadow: 0 2px 4px rgba(3, 27, 78, 0.06);
    -moz-box-shadow: 0 2px 4px rgba(3, 27, 78, 0.06);
    box-shadow: 0 2px 4px rgba(3, 27, 78, 0.06);
  }
  .review_card .user_info {
    text-align: center;
  }
  @media (max-width: 767px) {
    .review_card .user_info {
      margin-bottom: 10px;
    }
  }
  .review_card .user_info figure {
    width: 50px;
    height: 50px;
    overflow: hidden;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    margin-bottom: 10px;
  }
  @media (max-width: 767px) {
    .review_card .user_info figure {
      float: left;
    }
  }
  .review_card .user_info figure img {
    width: 50px;
    height: auto;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  .review_card .user_info h5 {
    font-size: 13px;
    font-size: 0.8125rem;
  }
  @media (max-width: 767px) {
    .review_card .user_info h5 {
      float: left;
      margin: 18px 0 0 15px;
    }
  }
  .review_card .review_content h4 {
    font-size: 18px;
    font-size: 1.125rem;
  }
  .review_card .review_content .rating {
    float: left;
    font-weight: 600;
    font-size: 26px;
    font-size: 1.625rem;
    color: #589442;
  }
  .review_card .review_content .rating small {
    font-size: 12px;
    font-size: 0.75rem;
    font-weight: 600;
  }
  .review_card .review_content .rating strong {
    font-size: 13px;
    font-size: 0.8125rem;
    font-weight: 400;
    color: #444;
  }
  @media (max-width: 575px) {
    .review_card .review_content .rating {
      float: none;
      display: block;
    }
  }
  @media (max-width: 575px) {
    .review_card .review_content .rating em {
      float: none;
      display: inline-block;
      margin-left: 10px;
    }
  }
  .review_card .review_content em {
    color: #999;
    float: right;
  }
  @media (max-width: 575px) {
    .review_card .review_content em {
      float: none;
      display: block;
      margin-top: 10px;
      margin-left: 0;
    }
  }
  .review_card .review_content ul {
    margin: 35px 0 25px 0;
    padding: 0;
  }
  .review_card .review_content ul li {
    display: inline-block;
    font-size: 13px;
    font-size: 0.8125rem;
    margin-right: 0;
  }
  @media (max-width: 575px) {
    .review_card .review_content ul li span {
      display: none;
    }
  }
  .review_card .review_content ul li a {
    background-color: #f0f0f0;
    padding: 7px 10px 5px 10px;
    -webkit-border-radius: 3px;
    -moz-border-radius: 3px;
    -ms-border-radius: 3px;
    border-radius: 3px;
    color: #444;
  }
  .review_card .review_content ul li a:hover {
    background-color: #589442;
    color: #fff;
  }
  .review_card .review_content ul li a i {
    margin-right: 5px;
  }
  @media (max-width: 575px) {
    .review_card .review_content ul li a i {
      margin-right: 0;
    }
  }
  .review_card .review_content ul li:last-child {
    float: right;
    margin-right: 0;
  }
  .review_card .reply {
    padding-top: 30px;
  }
  @media (max-width: 767px) {
    .review_card .reply .user_info {
      display: none;
    }
  }
  .review_card .reply .user_info figure {
    width: 50px;
    height: 50px;
    overflow: hidden;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    -ms-border-radius: 50%;
    border-radius: 50%;
    display: inline-block;
    position: relative;
    margin-bottom: 10px;
  }
  .review_card .reply .user_info figure img {
    width: 50px;
    height: auto;
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  .review_card .reply .user_info h5 {
    font-size: 14px;
    font-size: 0.875rem;
  }
  .review_card .reply .review_content {
    border-left: 5px solid #ededed;
    padding-left: 20px;
  }
  @media (max-width: 575px) {
    .review_card .reply .review_content strong {
      display: block;
    }
  }
  .review_card .reply .review_content em {
    color: #999;
    font-style: italic;
    float: right;
  }
  @media (max-width: 575px) {
    .review_card .reply .review_content em {
      float: none;
      display: block;
      margin-top: 10px;
    }
  }
  
  .dropdown {
    border: 1px dotted #c8c8c8;
    padding: 0;
    margin-bottom: 5px;
  }
  .dropdown a {
    padding: 15px 15px 12px 15px;
    line-height: 1;
    color: #444;
    font-weight: 500;
    display: block;
    position: relative;
  }
  .dropdown a:after {
    font-family: 'food';
    font-size: 21px;
    font-size: 1.3125rem;
    font-weight: bold;
    -moz-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.3s ease-in-out;
    -ms-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    position: absolute;
    right: 10px;
    top: 10px;
  }
  .dropdown.show a {
    color: #333;
  }
  .dropdown.show a:after {
    transform: rotateY(360deg);
    color: #333;
  }
  .dropdown .dropdown-menu {
    font-size: 14px;
    font-size: 0.875rem;
    background: #f8f8f8;
    border: 1px solid #ddd;
    -webkit-border-radius: 0;
    -moz-border-radius: 0;
    -ms-border-radius: 0;
    border-radius: 0;
    padding: 15px;
    width: 100.5%;
    left: -1px !important;
    right: -1px !important;
    margin: 5px 0 0 0;
  }
  .dropdown .dropdown-menu h4 {
    text-align: center;
    font-size: 14px;
    font-size: 0.875rem;
    text-transform: uppercase;
  }
  
  .dropdown.time a:after {
    content: '\0057';
  }
  
  .dropdown.time {
    margin-bottom: 25px;
  }
  
  .dropdown.day a:after {
    font-family: "ElegantIcons";
    content: "\e023";
    font-weight: 300;
  }
  
  .radio_select ul {
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
    text-align: center;
  }
  .radio_select ul li {
    display: inline-block;
    margin: 0;
    width: 23.5%;
  }
  .radio_select.chose_day ul li {
    width: 43.5%;
  }
  .radio_select input[type="radio"] {
    opacity: 0;
    position: fixed;
    width: 0;
  }
  .radio_select label {
    display: inline-block;
    text-align: center;
    width: 100%;
    padding: 8px;
    border: 1px dotted #c8c8c8;
    background-color: #fff;
    font-size: 16px;
    font-size: 1rem;
  }
  .radio_select label em {
    display: block;
    color: red;
    font-style: normal;
    font-size: 11px;
    font-size: 0.6875rem;
    font-weight: 500;
  }
  .radio_select label:hover {
    background-color: #dfd;
  }
  .radio_select input[type="radio"]:focus + label {
    border: 1px solid transparent;
    background-color: #589442;
    color: #fff;
  }
  .radio_select input[type="radio"]:focus + label em {
    color: #ffff66;
  }
  .radio_select input[type="radio"]:checked + label {
    border: 1px solid transparent;
    background-color: #589442;
    color: #fff;
  }
  .radio_select input[type="radio"]:checked + label em {
    color: #ffff66;
  }
  
  .other_info {
    background-color: #f9f9f9;
    padding: 25px 25px 5px 25px;
    margin-bottom: 20px;
  }
  .other_info h2 {
    margin-bottom: 35px;
  }
  
  .follow_us_detail a {
    color: #999;
    display: inline-block;
    margin: 5px 5px 0 0;
  }
  .follow_us_detail a:hover {
    color: #589442;
  }
  .follow_us_detail a i {
    font-size: 24px;
    font-size: 1.5rem;
  }
  